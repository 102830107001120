import styled, { createGlobalStyle } from 'styled-components';
import { Link } from 'react-router-dom';

export const GlobalStyle = createGlobalStyle`
  #root {
    height: 100%;
  }

  img {
    user-drag: none;
    user-select: none;
  }

  iframe {
    border: none;
    outline: none;
  }
  
  .ui.primary.button {
    background-color: #b11664;

    &:focus,
    &:visited {
      background-color: #b11664;
    }

    &:active,
    &:hover {
      background-color: #9d135e;
    }
  }
  
  .ui.fullscreen.modal {
    left: auto !important;
  }
`;

export const BlackLink = styled(Link)`
  color: #262626;

  &:hover {
    color: #262626;
    text-decoration: underline;
  }
`;
