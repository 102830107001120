import React from 'react';
import ReactDOM from 'react-dom';

import configureAxios from './config/axios.config';
import configureMoment from './config/moment.config';

import './global-styles';

import './utils/i18n';

import App from './App';

configureAxios();
configureMoment();

ReactDOM.render(<App />, document.getElementById('root'));

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready.then(registration => {
    registration.unregister();
  });
}
