import { BaseModel } from './shared.model';

export enum Roles {
  ADMIN = 'ADMIN',
  EXPERT = 'EXPERT',
  MAJOR_ACCOUNT = 'MAJOR_ACCOUNT',
}

export interface UserGoogleState {
  isConnected: boolean;
  isCalendarConfigured: boolean;
}

export interface User extends BaseModel<number> {
  email: string;
  firstName: string;
  lastName: string;
  role: Roles;
  phoneNumber: string;
  isActivated?: boolean;
  google?: UserGoogleState;
  missions: Array<number>;
}

export type DetailUser = User & {
  canUpdateRole: boolean;
};

export interface UserDetailModel extends BaseModel<number> {
  email: string;
  firstName: string;
  lastName: string;
  role: Roles;
  phoneNumber: string;
  isActivated?: boolean;
  google?: UserGoogleState;
  canUpdateRole: boolean;
  missions: Array<Missions>;
}

interface Missions {
  id: number;
  label: string;
}

export interface TrackingUser {
  id: number;
  firstName: string;
  lastName: string;
}
