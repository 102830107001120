import React, { Component } from 'react';

import { withTranslation, WithTranslation } from 'react-i18next';

import * as Styled from './NotFound.styles';

class NotFound extends Component<WithTranslation, {}> {
  public render(): JSX.Element {
    const { t } = this.props;

    return (
      <Styled.container>
        <Styled.text>{t('text')}</Styled.text>
        <Styled.homeLink to="/">{t('link.title')}</Styled.homeLink>
      </Styled.container>
    );
  }
}

export default withTranslation(['global-not-found'])(NotFound);
