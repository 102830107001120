import { ActionWithPayload } from '../types';
import { Action, AnyAction } from 'redux';
import { AuthActions } from './constants';
import { Profile } from './model';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import * as AuthService from '../../services/auth.service';
import { AUTH_LOGOUT_KEY } from '../../services/auth.service';
import { rightIO } from 'fp-ts/lib/TaskEither';
import { IO } from 'fp-ts/lib/IO';

export const updateProfile = (profile: Profile): ActionWithPayload<string, Profile> => {
  return {
    type: AuthActions.UPDATE_PROFILE,
    payload: profile,
  };
};

export const removeProfile = (): Action<string> => {
  return {
    type: AuthActions.REMOVE_PROFILE,
  };
};

export const logout = (): ThunkAction<Promise<void>, {}, {}, Action<string>> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return AuthService.logout()
      .chain(() => rightIO(new IO(() => window.localStorage.setItem(AUTH_LOGOUT_KEY, new Date().toISOString()))))
      .value.run() // ignore execution errors
      .then(() => {
        dispatch(removeProfile());
      });
  };
};
