import { fromNullable } from 'fp-ts/lib/Option';

export const getConfig = (): { [key: string]: string | undefined } => ({
  ...process.env,
  ...window._env_,
});

export const getConfigValue = (key: string) => {
  return fromNullable(getConfig()[key]);
};
