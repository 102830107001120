import axios from 'axios';

import { v4 as uuid } from 'uuid';

import { camelToSnake } from '../utils/string.utils';

/*
const getLoader = () => fromNullable(document.getElementById('loader'));
const startLoader = () => getLoader().map(loader => loader.classList.add('loading'));
const stopLoader = () => getLoader().map(loader => loader.classList.remove('loading'));*/

const getCookies = (): { [key: string]: string } => {
  return document.cookie.split(';').reduce(
    (cookies, item) => {
      const [name, value] = item.split('=');

      if (name && value) {
        cookies[name.trim()] = value;
      }

      return cookies;
    },
    {} as { [key: string]: string },
  );
};

const convertParamsToSnakeCase = (params: { [key: string]: any }) => {
  return Object.entries(params)
    .map(([key, value]) => [camelToSnake(key), value])
    .reduce(
      (acc, [key, value]) => {
        return {
          ...acc,
          [key]: value,
        };
      },
      {} as { [key: string]: any },
    );
};

export const getCSRFToken = () => getCookies()['MG_CSRF'];

const configureInterceptors = () => {
  axios.interceptors.request.use(config => {
    const xsrfToken = getCSRFToken();

    return {
      ...config,
      headers: {
        ...config.headers,
        ...(xsrfToken ? { 'X-CSRF-TOKEN': xsrfToken } : {}),
        'X-Transaction-Id': uuid(),
      },
      params: config.params ? convertParamsToSnakeCase(config.params) : undefined,
      withCredentials: true,
    };
  });
};

const configureAxios = () => {
  configureInterceptors();
};

export default configureAxios;
