import React, { Component, Suspense } from 'react';
import { Route, RouteComponentProps, Router, Switch, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
// Components & Containers
import PrivateRoute from '../../components/PrivateRoute';
import Navigation from '../../components/navigation/Navigation';
import LoadingRoute from '../../components/loadingRoute/LoadingRoute';
import NotFound from '../notFound/NotFound';
// Routes
import routes, { history } from '../../routes';
// Types
import { NavigationItem } from '../../components/navigation/Navigation.types';
// Styling
import * as Styled from './Root.styles';
import { GlobalStyle } from '../../global-styles/global.style';
import { getProfile } from '../../services/auth.service';
import { Profile } from '../../store/auth/model';
import { updateProfile } from '../../store/auth/actions';

interface RootProps {
  updateProfile: (profile: Profile) => void;
}

interface RootState {
  initialized: boolean;
}

const mapDispatchToProps = (dispatch: any) => ({
  updateProfile: (profile: Profile) => {
    dispatch(updateProfile(profile));
  },
});

class Root extends Component<RootProps, RootState> {
  state = {
    initialized: false,
  };

  private static createRoutes(): JSX.Element[] {
    return routes.map((route: NavigationItem, index: number) => {
      const props = {
        key: index,
        exact: true,
        path: route.path,
      };

      const { container: Container } = route;

      return route.auth ? (
        <PrivateRoute {...props} routeData={route} />
      ) : (
        <Route {...props}>
          <Container />
        </Route>
      );
    });
  }

  public componentDidMount() {
    return this.fetchProfile();
  }

  private fetchProfile = () => {
    return getProfile()
      .map(this.props.updateProfile)
      .run()
      .finally(() => this.setState({ initialized: true }));
  };

  public render() {
    // Check navigation is visible by checking for blacklist urls
    return (
      this.state.initialized && (
        <Router history={history}>
          <Styled.container>
            <GlobalStyle />
            <Styled.Loader id="loader" />
            <ConnectedRootNavigation />

            <Styled.content>
              <Suspense fallback={<LoadingRoute />}>
                <Switch>
                  {Root.createRoutes()}
                  <Route component={NotFound} />
                </Switch>
              </Suspense>
            </Styled.content>
          </Styled.container>
        </Router>
      )
    );
  }
}

function RootNavigation({ location }: RouteComponentProps) {
  // Only display navigation is route is authenticated
  const blacklist = routes.filter(r => !r.auth).map(r => r.path);
  if (blacklist.indexOf(location.pathname) > -1) {
    return null;
  }

  return <Navigation />;
}

const ConnectedRootNavigation = withRouter(RootNavigation);

export default connect(
  undefined,
  mapDispatchToProps,
)(Root);
