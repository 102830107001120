import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { TaskEither, tryCatch } from 'fp-ts/lib/TaskEither';
import { HttpError, HttpStatusCode } from '../model/http.model';
import { history } from '../routes';
import { Task } from 'fp-ts/lib/Task';
import { getCSRFToken } from '../config/axios.config';

const WHITE_LIST_REDIRECT = ['/authenticate', '/profile'];

const UNAVAILABLE_STATUS: Array<HttpStatusCode> = [
  HttpStatusCode.BAD_GATEWAY,
  HttpStatusCode.SERVICE_UNAVAILABLE,
  HttpStatusCode.GATEWAY_TIMEOUT,
];

const isWhiteList = (url: string) => WHITE_LIST_REDIRECT.filter(whiteUrl => url.includes(whiteUrl)).length > 0;

const onError = (err: unknown): HttpError => {
  const httpError = HttpError.fromAxiosError(err as AxiosError);

  const redirectToUnavailable = UNAVAILABLE_STATUS.includes(httpError.status);

  const redirectToLogin = httpError.url.exists(
    url => !isWhiteList(url) && httpError.status === HttpStatusCode.UNAUTHORIZED,
  );

  const referer = history.location.pathname;

  if (redirectToUnavailable) {
    history.push('/unavailable', {
      referer,
    });

    throw err;
  } else if (redirectToLogin) {
    history.push('/login', {
      referer,
    });

    throw err;
  }

  return httpError;
};

const mapData = <T = any>({ data }: AxiosResponse<T>) => data;

export const toBooleanResponse = () => true;

export const get = <T = any>(url: string, config?: AxiosRequestConfig): TaskEither<HttpError, T> => {
  return tryCatch(() => axios.get<T>(url, config), onError).map(mapData);
};

export const post = <T = any>(url: string, data?: any, config?: AxiosRequestConfig): TaskEither<HttpError, T> => {
  return tryCatch(() => axios.post<T>(url, data, config), onError).map(mapData);
};

export const put = <T = any>(url: string, data?: any, config?: AxiosRequestConfig): TaskEither<HttpError, T> => {
  return tryCatch(() => axios.put<T>(url, data, config), onError).map(mapData);
};

export const del = (url: string, data?: any, config?: AxiosRequestConfig): TaskEither<HttpError, boolean> => {
  return tryCatch(() => axios.delete(url, config), onError).map(toBooleanResponse);
};

export const patch = <T = any>(url: string, data?: any, config?: AxiosRequestConfig): TaskEither<HttpError, T> => {
  return tryCatch(() => axios.patch<T>(url, data, config), onError).map(mapData);
};

export const head = <T = any>(url: string, config?: AxiosRequestConfig): TaskEither<HttpError, T> => {
  return tryCatch(() => axios.head<T>(url, config), onError).map(mapData);
};

export interface StreamResult {
  fileName: string;
  stream: ReadableStream;
}
export const getAsStream = (url: string, method: string, body?: any): Task<StreamResult> => {
  const contentHeaders =
    body != null
      ? {
          'Content-Type': 'application/json',
        }
      : null;

  return new Task(() =>
    fetch(url, {
      method,
      body,
      credentials: 'same-origin',
      headers: {
        'X-CSRF-TOKEN': getCSRFToken(),
        ...contentHeaders,
      },
    }).then(response => {
      if (response.status >= 400) {
        throw new Error('invalid HTTP response');
      } else {
        const contentDispositon = response.headers.get('Content-Disposition');
        const fileName = contentDispositon ? contentDispositon.split('"')[1] : '';
        return { fileName: fileName ? fileName : '', stream: response.body as ReadableStream };
      }
    }),
  );
};
