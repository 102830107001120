import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colours } from '../../global-styles/variables';

export const container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const text = styled.p`
  font-size: 25px;
`;

export const homeLink = styled(Link)`
  color: ${colours.primary};
  font-size: 20px;
  font-weight: bold;

  &:hover {
    color: ${colours.primaryDarker[0]};
    text-decoration: underline;
  }
`;
