import { AuthState, defaultAuthState, Profile } from './model';
import { none, some } from 'fp-ts/lib/Option';

import { ActionWithPayload } from '../types';
import { AuthActions } from './constants';

const handleUpdateProfile = (state: AuthState, { payload }: ActionWithPayload<string, Profile>): AuthState => {
  return {
    profile: some(payload),
  };
};

const handleRemoveProfile = (): AuthState => {
  return {
    profile: none,
  };
};

export default function(state = defaultAuthState, action: ActionWithPayload<string, any>): AuthState {
  switch (action.type) {
    case AuthActions.UPDATE_PROFILE:
      return handleUpdateProfile(state, action);
    case AuthActions.REMOVE_PROFILE:
      return handleRemoveProfile();
    default:
      return state;
  }
}
