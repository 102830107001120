import React from 'react';

import * as Styled from './LoadingRoute.styles';
import { Loader } from 'semantic-ui-react';
import { WithTranslation, withTranslation } from 'react-i18next';

const LoadingRoute = ({ t }: WithTranslation) => {
  return (
    <Styled.container>
      <Loader active>{t('loading')}</Loader>
    </Styled.container>
  );
};

export default withTranslation('common')(LoadingRoute);
