import React, { Component } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps, withRouter } from 'react-router-dom';
// Type
import { NavigationItem } from './navigation/Navigation.types';
import { Roles } from '../model/user.model';
import { Option } from 'fp-ts/lib/Option';
import { RootState } from '../store/root-state';
import { selectProfile } from '../store/auth/selectors';
import { connect } from 'react-redux';
import { Profile } from '../store/auth/model';
import { AUTH_LOGOUT_KEY } from '../services/auth.service';
import { removeProfile } from '../store/auth/actions';

interface PrivateRouteProps {
  profile: Option<Profile>;
  routeData: NavigationItem;
  removeProfile: () => void;
}

const mapStateToProps = (state: RootState) => ({
  profile: selectProfile(state),
});

const mapDispatchToProps = {
  removeProfile,
};

class PrivateRoute extends Component<PrivateRouteProps & RouteProps & RouteComponentProps> {
  componentDidMount() {
    window.addEventListener('storage', this.syncLogout);
  }

  componentWillUnmount() {
    window.removeEventListener('storage', this.syncLogout);
    window.localStorage.removeItem(AUTH_LOGOUT_KEY);
  }

  private syncLogout = (event: StorageEvent) => {
    if (event.key === AUTH_LOGOUT_KEY) {
      this.props.removeProfile();
      return this.props.history.push('/login');
    }
  };

  private hasClearance = (clearances: Array<string>, role: Roles) =>
    clearances && clearances.length > 0 ? clearances.includes(role) : true;

  private handleRender = (props: any) => {
    const { profile, routeData } = this.props;

    return profile
      .map(p => this.hasClearance(routeData.clearance, p.role))
      .map(authorize =>
        authorize ? (
          React.createElement(routeData.container, props)
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        ),
      )
      .getOrElse(<Redirect to={{ pathname: '/login', state: { referer: props.location } }} />);
  };

  public render() {
    const { profile, routeData, ...rest } = this.props;

    return <Route {...rest} render={this.handleRender} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(PrivateRoute));
