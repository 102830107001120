import React, { Component } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
// Styling
import { Icon } from 'semantic-ui-react';
import * as Styled from './Navigation.styles';
// Assets
import logo from '../../assets/images/logo.svg';
// Routes
import * as routes from '../../routes';
// Types
import { NavigationItem } from './Navigation.types';

// Translate
import { withTranslation, WithTranslation } from 'react-i18next';
import { Option } from 'fp-ts/lib/Option';
import { RootState } from '../../store/root-state';
import { selectProfile } from '../../store/auth/selectors';
import { Profile } from '../../store/auth/model';
import { connect } from 'react-redux';
import { logout } from '../../store/auth/actions';
import { compose } from 'recompose';

interface NavigationProps {
  profile: Option<Profile>;
  logout: () => void;
}

const mapStateToProps = (state: RootState) => ({
  profile: selectProfile(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  logout: () => {
    dispatch(logout());
  },
});

class Navigation extends Component<NavigationProps & WithTranslation & RouteComponentProps> {
  private static renderHeader(): JSX.Element {
    return (
      <Styled.mainHeader>
        <Link to="/">
          <Styled.mainHeaderImage src={logo} alt="Marguerite" />
        </Link>
      </Styled.mainHeader>
    );
  }

  private renderSections(profile: Profile): Array<JSX.Element | null> {
    return routes.sections.map((section, i) => {
      const items = section.items.filter(item => item.clearance.includes(profile.role));

      return items.length > 0 ? (
        <Styled.section key={`section-${i}`}>
          {section.translateKey && (
            <Styled.navHeader>
              <Styled.navTitle>{this.props.t(`global-navigation:${section.translateKey}`)}</Styled.navTitle>
            </Styled.navHeader>
          )}
          {this.renderSectionElements(items)}
        </Styled.section>
      ) : null;
    });
  }

  private renderSectionElements(elements: Array<NavigationItem>): JSX.Element[] {
    return elements.map(element => {
      const title = this.props.t(`global-navigation:pageTitles.${element.translateKey}`);
      const props = {
        exact: element.path === '/',
        key: this.props.t(`global-navigation:pageTitles.${element.translateKey}`),
        to: element.path,
        activeClassName: 'active',
      };

      return <Styled.navLink {...props}>{title}</Styled.navLink>;
    });
  }

  private handleLogout = () => {
    this.props.history.push('/login');
    this.props.logout();
  };

  private renderFooter(profile: Profile): JSX.Element {
    return (
      <Styled.footerContainer>
        <Styled.footerUser to="/account">
          <Icon name="user" />
          {`${profile.firstName} ${profile.lastName}`}
        </Styled.footerUser>
        <Styled.footerLogout onClick={this.handleLogout}>
          <Icon name="log out" />
        </Styled.footerLogout>
      </Styled.footerContainer>
    );
  }

  public render(): JSX.Element | null {
    return this.props.profile
      .map(p => (
        <Styled.container>
          {Navigation.renderHeader()}
          <Styled.sectionsWrapper>{this.renderSections(p)}</Styled.sectionsWrapper>
          {this.renderFooter(p)}
        </Styled.container>
      ))
      .toNullable();
  }
}

export default compose<NavigationProps & WithTranslation, {}>(
  withTranslation(['common', 'global-navigation']),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(withRouter(Navigation));
