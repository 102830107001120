import { ComponentType, lazy } from 'react';

import { fromNullable } from 'fp-ts/lib/Option';

import { MenuSection, NavigationItem } from './components/navigation/Navigation.types';

import { Roles } from './model/user.model';
import { createBrowserHistory } from 'history';

export interface Clearances {
  free: Array<Roles>;
  all: Array<Roles>;
  adminExpert: Array<Roles>;
  admin: Array<Roles>;
}

export const clearances: Clearances = {
  free: [],
  all: [Roles.ADMIN, Roles.EXPERT, Roles.MAJOR_ACCOUNT],
  adminExpert: [Roles.ADMIN, Roles.EXPERT],
  admin: [Roles.ADMIN],
};

const safeLazy = <T extends ComponentType<any>>(factory: () => Promise<{ default: T }>) =>
  lazy(() =>
    factory().catch(err => {
      window.location.reload();

      return err;
    }),
  );

const routes: Array<NavigationItem> = [
  {
    translateKey: 'auth-login',
    path: '/login',
    container: safeLazy(() => import('./containers/login/Login')),
    auth: false,
    clearance: clearances.free,
  },
  {
    translateKey: 'auth-activate-account',
    path: '/account/:token/activate',
    container: safeLazy(() => import('./containers/activateAccount/ActivateAccount')),
    auth: false,
    clearance: clearances.free,
  },
  {
    translateKey: 'auth-forgot-password',
    path: '/password/forgot',
    container: safeLazy(() => import('./containers/forgotPassword/ForgotPassword')),
    auth: false,
    clearance: clearances.free,
  },
  {
    translateKey: 'auth-reset-password',
    path: '/password/:token/reset',
    container: safeLazy(() => import('./containers/resetPassword/ResetPassword')),
    auth: false,
    clearance: clearances.free,
  },
  {
    translateKey: 'home',
    path: '/',
    container: safeLazy(() => import('./containers/home/Home')),
    auth: true,
    clearance: clearances.all,
  },
  {
    translateKey: 'user-account',
    path: '/account',
    container: safeLazy(() => import('./containers/userAccount/UserAccount')),
    auth: true,
    clearance: clearances.all,
  },
  {
    translateKey: 'customers.main',
    path: '/customers',
    container: safeLazy(() => import('./containers/customerRecords/Customers')),
    auth: true,
    clearance: clearances.all,
  },
  {
    translateKey: 'customers.create',
    path: '/customers/new',
    container: safeLazy(() => import('./containers/customerRecords/AddCustomer')),
    auth: true,
    clearance: clearances.all,
  },
  {
    translateKey: 'customers.detail',
    path: '/customers/:id',
    container: safeLazy(() => import('./containers/customerRecords/CustomerDetail')),
    auth: true,
    clearance: clearances.all,
  },
  {
    translateKey: 'customers.detail',
    path: '/customers/:id/certificates',
    container: safeLazy(() => import('./containers/customerRecords/CustomerCertificates')),
    auth: true,
    clearance: clearances.all,
  },
  {
    translateKey: 'customers.detail',
    path: '/customers/:id/messages',
    container: safeLazy(() => import('./containers/customerRecords/CustomerMessages')),
    auth: true,
    clearance: clearances.all,
  },
  {
    translateKey: 'diagnostic.main',
    path: '/diagnostics',
    container: safeLazy(() => import('./containers/diagnostics/Diagnostics')),
    auth: true,
    clearance: clearances.all,
  },
  {
    translateKey: 'diagnostic.create',
    path: '/diagnostics/:id/draft',
    container: safeLazy(() => import('./containers/diagnostics/CreateDiagnostic')),
    auth: true,
    clearance: clearances.all,
  },
  {
    translateKey: 'diagnostic.detail',
    path: '/diagnostics/:id',
    container: safeLazy(() => import('./containers/diagnostics/DiagnosticDetail')),
    auth: true,
    clearance: clearances.all,
  },
  {
    translateKey: 'diagnostic.validation',
    path: '/diagnosis-validation/:token',
    container: safeLazy(() => import('./containers/diagnostics/DiagnosticValidation')),
    auth: false,
    clearance: clearances.all,
  },
  {
    translateKey: 'diagnostic.detail',
    path: '/diagnostics/:id/report',
    container: safeLazy(() => import('./containers/diagnostics/DiagnosticReport')),
    auth: true,
    clearance: clearances.all,
  },
  {
    translateKey: 'exports',
    path: '/exports',
    container: safeLazy(() => import('./containers/exports/Exports')),
    auth: true,
    clearance: clearances.admin,
  },
  {
    translateKey: 'orders.main',
    path: '/orders',
    container: safeLazy(() => import('./containers/orders/Orders')),
    auth: true,
    clearance: clearances.all,
  },
  {
    translateKey: 'orders.create',
    path: '/orders/new',
    container: safeLazy(() => import('./containers/orders/CreateOrder')),
    auth: true,
    clearance: clearances.all,
  },
  {
    translateKey: 'orders.pay',
    path: '/orders/:id/payment/installments',
    container: safeLazy(() => import('./containers/orders/InInstallmentsPayment')),
    auth: true,
    clearance: clearances.all,
  },
  {
    translateKey: 'orders.pay',
    path: '/orders/:id/payment/waiting',
    container: safeLazy(() => import('./containers/orders/WaitingPayment')),
    auth: true,
    clearance: clearances.all,
  },
  {
    translateKey: 'orders.pay',
    path: '/orders/:id/payment/:token',
    container: safeLazy(() => import('./containers/orders/SimplePayment')),
    auth: true,
    clearance: clearances.all,
  },
  {
    translateKey: 'orders.detail',
    path: '/orders/:id',
    container: safeLazy(() => import('./containers/orders/OrderDetail')),
    auth: true,
    clearance: clearances.all,
  },
  {
    translateKey: 'providers.main',
    path: '/providers',
    container: safeLazy(() => import('./containers/providers/Providers')),
    auth: true,
    clearance: clearances.adminExpert,
  },
  {
    translateKey: 'providers.create',
    path: '/providers/new',
    container: safeLazy(() => import('./containers/providers/CreateProvider')),
    auth: true,
    clearance: clearances.adminExpert,
  },
  {
    translateKey: 'providers.detail',
    path: '/providers/:id',
    container: safeLazy(() => import('./containers/providers/ProviderDetail')),
    auth: true,
    clearance: clearances.adminExpert,
  },
  {
    translateKey: 'providers.service-create',
    path: '/providers/:id/services/new',
    container: safeLazy(() => import('./containers/providers/CreateService')),
    auth: true,
    clearance: clearances.adminExpert,
  },
  {
    translateKey: 'providers.service-detail',
    path: '/providers/services/:id',
    container: safeLazy(() => import('./containers/providers/ServiceDetail')),
    auth: true,
    clearance: clearances.adminExpert,
  },
  {
    translateKey: 'vouchers',
    path: '/vouchers',
    container: safeLazy(() => import('./containers/vouchers/Vouchers')),
    auth: true,
    clearance: clearances.admin,
  },
  {
    translateKey: 'users.main',
    path: '/users',
    container: safeLazy(() => import('./containers/users/Users')),
    auth: true,
    clearance: clearances.admin,
  },
  {
    translateKey: 'users.add',
    path: '/users/new',
    container: safeLazy(() => import('./containers/users/AddUser')),
    auth: true,
    clearance: clearances.admin,
  },
  {
    translateKey: 'users.detail',
    path: '/users/:id',
    container: safeLazy(() => import('./containers/users/UserDetail')),
    auth: true,
    clearance: clearances.admin,
  },
  {
    translateKey: 'payment-failures',
    path: '/payment-failures',
    container: safeLazy(() => import('./containers/paymentFailures/PaymentFailures')),
    auth: true,
    clearance: clearances.admin,
  },
  {
    translateKey: 'global-unavailable',
    path: '/unavailable',
    container: safeLazy(() => import('./containers/unavailable/Unavailable')),
    auth: false,
    clearance: clearances.free,
  },
  {
    translateKey: 'generate-certificates',
    path: '/generate-certificates',
    container: lazy(() => import('./containers/generateCertificates/GenerateCertificates')),
    auth: true,
    clearance: clearances.admin,
  },
  {
    translateKey: 'missions.main',
    path: '/missions',
    container: lazy(() => import('./containers/missions/Missions')),
    auth: true,
    clearance: clearances.admin,
  },
  {
    translateKey: 'missions.add',
    path: '/missions/new',
    container: safeLazy(() => import('./containers/missions/AddMission')),
    auth: true,
    clearance: clearances.admin,
  },
  {
    translateKey: 'missions.detail',
    path: '/missions/:id',
    container: safeLazy(() => import('./containers/missions/MissionDetail')),
    auth: true,
    clearance: clearances.admin,
  },
];

export const findRoute = (pathMatch: string): NavigationItem => {
  return fromNullable(routes.find(route => route.path === pathMatch)).getOrElse({
    translateKey: 'global-not-found',
    path: '',
    container: null,
    auth: false,
    clearance: clearances.all,
  });
};

export const sections: Array<MenuSection> = [
  {
    translateKey: '',
    items: [findRoute('/')],
  },
  {
    translateKey: 'categories.first',
    items: [findRoute('/customers'), findRoute('/orders'), findRoute('/diagnostics')],
  },
  {
    translateKey: 'categories.second',
    items: [findRoute('/providers')],
  },
  {
    translateKey: 'categories.third',
    items: [
      findRoute('/users'),
      findRoute('/vouchers'),
      findRoute('/exports'),
      findRoute('/payment-failures'),
      findRoute('/generate-certificates'),
      findRoute('/missions'),
    ],
  },
];

export const history = createBrowserHistory();

export default routes;
