import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { store } from './store';
import { StripeProvider } from 'react-stripe-elements';
import { getConfigValue } from './utils/env.utils';
import Root from './containers/root/Root';
import { SemanticToastContainer } from 'react-semantic-toasts';

const stripeApiKey = getConfigValue('REACT_APP_STRIPE_API_KEY').getOrElse('');

const App = () => (
  <Suspense fallback={<div />}>
    <Provider store={store}>
      <StripeProvider apiKey={stripeApiKey}>
        <Root />
      </StripeProvider>
    </Provider>
    <SemanticToastContainer />
  </Suspense>
);

export default App;
