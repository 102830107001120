import styled, { keyframes } from 'styled-components';
import { navigationWidth, colours } from '../../global-styles/variables';

export const container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  overflow-y: hidden;
`;

export const content = styled.div`
  flex: 1;
  width: calc(100% - ${navigationWidth}px);
  background-color: ${colours.background};
`;

const loadingAnimation = keyframes`
  from { left: -100px; width: 30%; }
  50% { width: 30%; }
  70% { width: 70%; }
  80% { left: 50%; }
  95% { left: 120%; }
  to { left: 100%; }
`;

export const Loader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  height: 4px;
  width: 100%;

  &::before {
    content: '';
    display: none;
    position: absolute;
    height: 3px;
    width: 200px;
    background: ${colours.primaryLighter[1]};
    z-index: 1000;
    animation: ${loadingAnimation} 2s linear infinite;
  }

  &.loading {
    &::before {
      display: block;
    }
  }
`;
