import { none, Option } from 'fp-ts/lib/Option';
import { User } from '../../model/user.model';

export type Profile = Pick<User, 'email' | 'firstName' | 'lastName' | 'phoneNumber' | 'role'>;

export interface AuthState {
  profile: Option<Profile>;
}

export const defaultAuthState: AuthState = {
  profile: none,
};
