import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { initReactI18next } from 'react-i18next';

// Search all .json file in fr folder
const req = require.context('../locales/fr', true, /\.(json)$/);

// Format french translations
const frenchTranslations = req.keys().reduce((acc, curr) => {
  const key = curr.replace('./', '').replace('.json', '');

  return {
    ...acc,
    [key]: req(curr),
  };
}, {});

export default i18n
  //.use(back)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'fr',
    lng: 'fr',
    debug: false,
    returnObjects: true,
    appendNamespaceToMissingKey: true,

    interpolation: {
      escapeValue: false,
    },

    resources: {
      fr: frenchTranslations,
    },
  });
