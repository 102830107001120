import { get, post, toBooleanResponse } from './http.service';
import {
  ActivateAccountBody,
  AuthenticateBody,
  AuthenticateResponse,
  ForgotPasswordBody,
  ResetPasswordBody,
  GetActivateEmailResponse,
  GetResetPasswordEmailResponse,
} from '../model/auth.model';
import { TaskEither } from 'fp-ts/lib/TaskEither';
import { HttpError } from '../model/http.model';
import { Profile } from '../store/auth/model';

export const AUTH_LOGOUT_KEY: string = 'MG_BACK_LOGOUT';

export const login = (credentials: AuthenticateBody): TaskEither<HttpError, Profile> => {
  return post<AuthenticateResponse>('/back/authenticate', credentials).chain(getProfile);
};

export const getProfile = (): TaskEither<HttpError, Profile> => {
  return get<Profile>('/back/profile');
};

export const logout = () => {
  return post('/back/logout');
};

export const getActivateEmail = (token: string) => {
  return get<GetActivateEmailResponse>(`/back/account/${token}/activate`);
};

export const activateAccount = (token: string, body: ActivateAccountBody): TaskEither<HttpError, Profile> => {
  return post(`/back/account/${token}/activate`, body).chain(getProfile);
};

export const forgotPassword = (body: ForgotPasswordBody) => {
  return post('/back/password/forgot', body).map(toBooleanResponse);
};

export const getResetEmail = (token: string) => {
  return get<GetResetPasswordEmailResponse>(`/back/password/${token}/reset`);
};

export const resetPassword = (token: string, body: ResetPasswordBody): TaskEither<HttpError, Profile> => {
  return post(`/back/password/${token}/reset`, body).chain(getProfile);
};
