import styled from 'styled-components';
import { colours, navigationWidth } from '../../global-styles/variables';
import { NavLink, Link } from 'react-router-dom';

import navigationBackground from '../../assets/images/navigation-background.svg';

export const container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${navigationWidth}px;
  height: 100vh;

  color: ${colours.white};
  background-color: ${colours.primaryDarker[0]};
  box-shadow: 3px 0 20px 0 rgba(0, 0, 0, 0.15);

  z-index: 1000;
  overflow: hidden;
`;

export const mainHeader = styled.div`
  width: 100%;
  margin-bottom: 7px;
  padding: 14px 30px 7px 30px;
  background: ${colours.white};
  text-align: center;
`;

export const mainHeaderImage = styled.img`
  width: 114px;
  margin: 0 auto;

  :hover {
    cursor: pointer;
  }
`;

export const sectionsWrapper = styled.div`
  flex: 1;
  width: ${navigationWidth + 15}px;
  overflow-x: hidden;
  overflow-y: scroll;
  background: url(${navigationBackground}) no-repeat bottom left ${colours.primaryDarker[0]};
  background-size: contain;
`;

export const section = styled.div`
  width: 100%;
  padding: 2px 0 18px;
`;

export const navHeader = styled.div`
  padding: 5px 14px;
  text-transform: uppercase;
  border-bottom: 1px solid ${colours.white};
  opacity: 0.5;
`;

export const navTitle = styled.h5`
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.4px;
`;

export const navLink = styled(NavLink)`
  display: block;
  padding: 8px 10px 8px 18px;
  color: rgba(255, 255, 255, 0.8);
  line-height: normal;
  border-left: 6px solid transparent;
  transition: all 0.05s ease-in;

  &.active,
  &:hover {
    cursor: pointer;
    color: ${colours.secondary};
    border-left-color: ${colours.secondary};
  }
`;

export const footerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  padding: 14px;
  background: ${colours.primaryDarker[1]};
`;

export const footerUser = styled(Link)`
  display: flex;
  align-items: baseline;
  color: rgba(255, 255, 255, 0.8);

  :hover {
    cursor: pointer;
    color: ${colours.secondary};
  }
`;

export const footerLogout = styled.p`
  font-size: 21px;
  margin-bottom: 5px;
  opacity: 0.85;

  :hover {
    cursor: pointer;
    color: ${colours.secondary};
  }
`;
