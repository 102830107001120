export const colours = {
  primary: '#b11664',
  primaryLighter: ['#d88ab1', '#f5b5d2', '#f5dbe8', '#fdf8fb'],
  primaryDarker: ['#9d135e', '#8b1052'],

  secondary: '#dedc00',
  secondaryLighter: ['#F9F6D0'],
  secondaryDarker: ['#cbc900'],

  tertiary: '#755d69',
  tertiaryLighter: ['#ac9ea5', '#d4c9c6', '#ddd5d4', '#e8e2e1'],

  background: '#f5f4f2',
  backgroundLight: '#fcfcfb',

  error: '#9d135e',
  errorBorder: '#d88ab1',
  errorBackground: '#fdf8fb',

  body: '#262626',

  white: '#ffffff',
  black: '#000000',
  grey: '#d5ced2',
};

export const navigationWidth = 250;
