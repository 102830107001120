import * as O from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';

export const camelToSnake = (value: string) => {
  return value.replace(/[\w]([A-Z])/g, m => m[0] + '_' + m[1]).toLowerCase();
};

export function filterEmptyStringToOption<T extends string>(value: T | null | undefined): O.Option<T> {
  return pipe(
    O.fromNullable(value),
    O.filter(v => v !== ''),
  );
}
